import {
	Box,
	Toolbar
} from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import { useTheme } from '@mui/material/styles'
import type { FC, ReactElement } from 'react'
import AppbarLogo from './PageTitle'
import { Link } from 'react-router-dom'

const Appbar: FC = (): ReactElement => {
	const theme = useTheme()

	return (
		<MuiAppBar
			position="sticky"
			elevation={0}
			sx={
				{
					backgroundColor: 'background.default',
					zIndex: theme.zIndex.drawer + 1,
					borderBottom: 1,
					borderColor: 'divider',
				}
			}
			data-testid="mbai-main-appbar"
			id="mbai-main-appbar"
		>
			<Toolbar>
				<Box
					sx={
						{
							flexGrow: 1,
							display: 'flex',
							alignItems: 'center',
						}
					}
					className="selectorAppbar"
				>
					<Link to="/">
						<AppbarLogo />
					</Link>
				</Box>
			</Toolbar>
		</MuiAppBar>
	)
}

export default Appbar
