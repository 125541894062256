import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { getCookie } from "../utils/getCookie";
import { Microsoft } from "@mui/icons-material";

const SignUpPage: React.FC = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password2: "",
    tac_accepted: false,
    pp_accepted: false,
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    try {
      const response = await axios.post("/auth/api/register/", formData, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
      });

      if (response.status === 201) {
        navigate("/login", {
          state: { successMessage: "Registration successful! Please check your email to verify your account and complete your registration." },
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setErrors(error.response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 400, mx: "auto", mt: 8 }}>
      <Typography variant="h4" textAlign="center">
        Sign Up
      </Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          href="http://mbai.local.infrastructure.apigateway.ai:8080/core/api/authentication/azure/signup/"
          sx={{ mb: 2 }}
          startIcon={<Microsoft />}
          disabled={!formData.tac_accepted || !formData.pp_accepted}
        >
          Sign Up with Microsoft
        </Button>
      </Box>
      <Typography variant="body2" align="center" sx={{ mb: 2 }}>
        Or
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="First Name"
              name="first_name"
              fullWidth
              value={formData.first_name}
              onChange={handleInputChange}
              error={!!errors.first_name}
              helperText={errors.first_name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Last Name"
              name="last_name"
              fullWidth
              value={formData.last_name}
              onChange={handleInputChange}
              error={!!errors.last_name}
              helperText={errors.last_name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              fullWidth
              value={formData.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              name="password"
              type="password"
              fullWidth
              value={formData.password}
              onChange={handleInputChange}
              error={!!errors.password}
              helperText={errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Confirm Password"
              name="password2"
              type="password"
              fullWidth
              value={formData.password2}
              onChange={handleInputChange}
              error={!!errors.password2}
              helperText={errors.password2}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.tac_accepted}
                  onChange={handleInputChange}
                  name="tac_accepted"
                />
              }
              label="I accept the Terms and Conditions"
            />
            {errors.tac_accepted && (
              <Typography color="error" variant="caption">
                {errors.tac_accepted}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.pp_accepted}
                  onChange={handleInputChange}
                  name="pp_accepted"
                />
              }
              label="I accept the Privacy Policy"
            />
            {errors.pp_accepted && (
              <Typography color="error" variant="caption">
                {errors.pp_accepted}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              loading={loading}
            >
              Sign Up
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SignUpPage;
