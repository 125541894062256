import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import Appbar from './Appbar';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <Box sx={{ flexGrow: 1, width: '100%', overflow: 'hidden' }}>
      <Appbar />
      <Box sx={{ pt: 10, display: 'flex', justifyContent: 'center', alignItems: 'flex-start', minHeight: '100vh' }}>
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
