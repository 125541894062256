import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Divider,
  Grid,
  Snackbar,
  TextField
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCookie } from "../utils/getCookie";

const ResetPasswordPage: React.FC = () => {
  const [formData, setFormData] = useState({
    password: "",
    password2: "",
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [generalError, setGeneralError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});
    setGeneralError(null);

    const token = searchParams.get("token");
    if (!token) {
      setGeneralError("Invalid or missing token");
      setLoading(false);
      setSnackbarOpen(true);
      return;
    }

    if (formData.password !== formData.password2) {
      setErrors({ password2: "Passwords do not match" });
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `/auth/api/reset-password/?token=${encodeURIComponent(token)}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
        }
      );

      if (response.status === 200) {
        navigate("/login", {
          state: { successMessage: "Password updated successfully" },
        });
      } else if (response.status === 500 || response.status === 400) {
        setGeneralError("An unexpected error occurred. Please try again.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 400 || status === 500) {
          setGeneralError(data.error ?? "An unexpected error occurred. Please try again.");
          setSnackbarOpen(true);
        } else {
          setErrors(data);
        }
      } else {
        setGeneralError("An unexpected error occurred. Please try again.");
        setSnackbarOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setGeneralError(null);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 400, mx: "auto", mt: 8 }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
          variant="filled"
        >
          {generalError}
        </Alert>
      </Snackbar>

      <Divider sx={{ mt: 2, mb: 2 }}>Enter New Password</Divider>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="New Password"
              name="password"
              type="password"
              fullWidth
              value={formData.password}
              onChange={handleInputChange}
              error={!!errors.password}
              helperText={errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Confirm New Password"
              name="password2"
              type="password"
              fullWidth
              value={formData.password2}
              onChange={handleInputChange}
              error={!!errors.password2}
              helperText={errors.password2 || (errors.password2 && "Passwords do not match")}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              loading={loading}
            >
              Reset Password
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ResetPasswordPage;
