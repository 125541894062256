import { Box } from '@mui/material';
import type { FC, ReactElement } from 'react';
import darkFullLogo from '../assets/images/mbai-official-logo-favicon-white-2023.png';

const AppbarLogo: FC = (): ReactElement => {
	return (
		<Box
			component="img"
			src={darkFullLogo}
			alt="MontBlancAI Logo"
			sx={{
				maxWidth: 150,
				height: 'auto',
			}}
		/>
	);
}

export default AppbarLogo;
