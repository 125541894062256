import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../assets/css/app.css";
import { getCookie } from "../utils/getCookie";
import { Microsoft } from '@mui/icons-material';

const LoginPage: React.FC = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [errors, setErrors] = useState<{ email?: string; password?: string; general?: string }>({});
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const authBackendBaseUrl = process.env.REACT_APP_AUTH_BACKEND_BASE_URL || "http://mbai.local.infrastructure.authentication.ai:8765/"

  const urlParams = new URLSearchParams(location.search);
  const nextUrl = urlParams.get("next") || "/";

  useEffect(() => {
    if (location.state?.successMessage) {
      setSnackbarMessage(location.state.successMessage);
      setSnackbarOpen(true);
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name as "email" | "password"]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleTogglePasswordVisibility = () => {
    setPasswordHidden((prev) => !prev);
  };

  const validateForm = () => {
    const validationErrors: { email?: string; password?: string } = {};
    if (!formData.email) validationErrors.email = "Email is required";
    if (!formData.password) validationErrors.password = "Password is required";
    return validationErrors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        setLoading(false);
        return;
    }

    try {
        const response = await axios.post(
            `/auth/api/login/?next=${encodeURIComponent(nextUrl)}`,
            formData,
            {
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
            }
        );

        if (response.status === 200 && response.data.redirect_url) {
            window.location.href = response.data.redirect_url;
        } else {
            handleServerError(response.data);
        }
    } catch (error) {
        handleAxiosError(error);
    } finally {
        setLoading(false);
    }
};


  const handleServerError = (data: any) => {
    console.error("Login failed:", data);
    setErrors({
      email: data.email || "",
      password: data.password || "",
      general: "Invalid credentials, please try again.",
    });
  };

  const handleAxiosError = (error: unknown) => {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401) {
        setErrors({
          general: "Invalid email or password. Please try again.",
        });
      } else {
        setErrors({
          email: data.email || "",
          password: data.password || "",
          general: "An unexpected error occurred. Please try again later.",
        });
      }
    } else {
      setErrors({
        general: "Unable to connect to the server. Please check your internet connection.",
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage(null);
  };

  return (
    <Box sx={{ flexGrow: 1, width: "100%", overflow: "hidden" }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%', color: 'white' }} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Box display="flex" justifyContent="center" alignItems="flex-start" minHeight="100vh" sx={{ pt: 10 }}>
        <Card variant="outlined" sx={{ maxWidth: 400, width: "100%" }}>
          <CardHeader
            title="Login to your account"
            titleTypographyProps={{ variant: "h1", textAlign: "center" }}
            sx={{ paddingBottom: 0 }}
          />
          <CardContent>
            <Box sx={{ mb: 2 }}>
              <Button
                variant="contained"
                fullWidth
                href={`auth/api/auth_backends/azure/login/?next=${encodeURIComponent(authBackendBaseUrl)}&state=${encodeURIComponent(nextUrl)}`}
                sx={{ mb: 2 }}
                startIcon={<Microsoft />}
              >
                Sign in with Microsoft
              </Button>
            </Box>
            <Typography variant="body2" align="center" sx={{ mb: 2 }}>
              <Divider
                sx={
                  { mt: 2 }
                }
              >
                Or
              </Divider>
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    name="email"
                    fullWidth
                    value={formData.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonOutlineTwoToneIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    name="password"
                    type={passwordHidden ? "password" : "text"}
                    fullWidth
                    value={formData.password}
                    onChange={handleInputChange}
                    error={!!errors.password}
                    helperText={errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                            {passwordHidden ? <VisibilityOffTwoToneIcon color="primary" /> : <RemoveRedEyeTwoToneIcon color="primary" />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {errors.general && (
                  <Grid item xs={12}>
                    <Typography color="error" variant="body2" align="center">
                      {errors.general}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    loading={loading}
                  >
                    Sign In
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
            <Typography variant="body2" textAlign="center" sx={{ mt: 2 }}>
              Don&apos;t have an account? <Link to="/signup" style={{ color: "#cb793a", textDecoration: "none" }}>Sign up</Link>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default LoginPage;
